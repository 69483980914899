import { ApiError } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

export type CommercialOfferRevenueRequestDataViewType = 'day' | 'month' | 'week' | 'year'

type GetCommercialOfferRevenueRequestData = {
  viewType: CommercialOfferRevenueRequestDataViewType
  withTest?: boolean
  startDate?: string
  endDate?: string
}

const graphInfoSchema = yup.object({
  period: yup.string().defined(),
  totalprice: yup.number().defined(),
})
export type GraphInfo = yup.InferType<typeof graphInfoSchema>

const responseSchema = yup.object({
  graphRevenueInfo: yup.array().of(graphInfoSchema.defined()).defined(),
  graphCTVInfo: yup.array().of(graphInfoSchema.defined()).defined(),
})
export type GetCommercialOfferRevenueResponse = yup.InferType<typeof responseSchema>

const getCommercialOfferRevenueRequest = (params?: GetCommercialOfferRevenueRequestData) =>
  apiRequest<GetCommercialOfferRevenueResponse>({
    method: 'GET',
    params,
    url: API_LIST.commercialOffer.commercialOfferRevenue,
    responseSchema,
  })

export const useGetCommercialOfferRevenueRequest = (
  params?: GetCommercialOfferRevenueRequestData,
) =>
  useQuery<GetCommercialOfferRevenueResponse, ApiError>({
    queryKey: [API_TAGS.ORDERS.root.commercialOfferRevenue, params],
    queryFn: () => getCommercialOfferRevenueRequest(params),
  })
