import { forwardRef, useCallback, useMemo } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { DateISOString } from '@share/@types'
import cn from 'classnames'

import { DatePickerInput } from './datepicker-custom-input'
import {
  convertDateISOStringToDate,
  convertDateToDateISOString,
  renderDatePickerCustomHeader,
} from './utils'

import s from './datepicker.module.scss'

export interface DatePickerProps
  extends Omit<ReactDatePickerProps, 'onChange' | 'value' | 'selectsRange' | 'selectsMultiple'> {
  placeholder?: string
  amountYearsShown?: number
  disabled?: boolean
  invalid?: boolean
  value: DateISOString | null
  onChange: (date: DateISOString | null) => void
}

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      className,
      value,
      placeholder,
      disabled,
      amountYearsShown = 5,
      invalid,
      onChange,
      yearItemNumber = 12,
      showYearPicker = false,
      showMonthYearPicker = false,
      ...datePickerProps
    },
    ref,
  ) => {
    const rootClassName = cn(s.root, className)

    const valueToUse = useMemo(() => convertDateISOStringToDate(value), [value])

    const handleOnChange = useCallback(
      (date: Date | null) => {
        const dateISOString = convertDateToDateISOString(date)
        onChange(dateISOString)
      },
      [onChange],
    )

    return (
      <div className={rootClassName}>
        <ReactDatePicker
          selected={valueToUse}
          dateFormat='dd MMM yyyy'
          onChange={handleOnChange}
          popperPlacement='bottom-end'
          wrapperClassName={s.wrapper}
          calendarClassName={s.calendar}
          showPopperArrow={false}
          placeholderText={placeholder}
          customInput={<DatePickerInput ref={ref} disabled={disabled} invalid={invalid} />}
          showYearPicker={showYearPicker}
          showMonthYearPicker={showMonthYearPicker}
          yearItemNumber={yearItemNumber}
          renderCustomHeader={(customHeaderProps) =>
            renderDatePickerCustomHeader({
              showMonthYearPicker,
              showYearPicker,
              customHeaderProps,
              amountYearsShown,
              yearItemNumber,
            })
          }
          {...datePickerProps}
        />
      </div>
    )
  },
)

DatePicker.displayName = 'DatePicker'
